$primary: #4B00FF;

:root {
  --white: #ffffff;
  --primary: #{$primary};
  --primary-hover: #{mix($primary, rgba(0,0,0,.2))};
  --primary-active: #{mix($primary, rgba(0,0,0,.4))};
  --primary-rgb: 75, 0, 255;
  --text: #2c2c2c;
  --bg: #f6f6f6;
  --bg-light: #fafafa;
  --border: #e1e1e1;
  --secondary: #293845;
  --warning: #e47a04;
  --warning-rgb: 228, 122, 4;
  --mid-gray: #757B86;
  --dark-gray: #4B5565;
  --disabled-gray: #8A929F;
  --err-red: #DD0005;
  --green: #10A256;
  --filter-white: invert(100%) sepia(3%) saturate(363%) hue-rotate(275deg) brightness(116%) contrast(100%);
  --filter-primary: invert(9%) sepia(100%) saturate(6590%) hue-rotate(262deg) brightness(96%) contrast(129%);
  --filter-primary-hover: invert(46%) sepia(92%) saturate(3543%) hue-rotate(236deg) brightness(95%) contrast(105%);
  --filter-secondary: invert(19%) sepia(15%) saturate(1061%) hue-rotate(166deg) brightness(94%) contrast(91%);
  --filter-warning: invert(55%) sepia(37%) saturate(5153%) hue-rotate(7deg) brightness(97%) contrast(97%);
  --filter-disabled: invert(65%) sepia(15%) saturate(260%) hue-rotate(178deg) brightness(86%) contrast(88%);
}
